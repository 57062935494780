var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.levels'))+" ")]},proxy:true},(_vm.hasAccess('levels', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[_c('b-table',{attrs:{"fields":_vm.tableHeader,"items":_vm.levels,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name[_vm.localLang])+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:("Level gift"),expression:"`Level gift`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"to":{
                            name: 'Levels Gifts',
                            query: {
                                id: item.id
                            }
                        },"variant":"flat-dark"}},[_c('feather-icon',{attrs:{"icon":"GiftIcon"}})],1),(_vm.hasAccess('levels', 'update'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.edit')))),expression:"`${$t('button.edit')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-dark"},on:{"click":function($event){return _vm.openModalToUpdate(item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1):_vm._e(),(_vm.hasAccess('levels', 'delete'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.delete')))),expression:"`${$t('button.delete')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.deleteLevel(item.id, index)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1):_vm._e()],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":false,"no-close-on-backdrop":false,"title":_vm.$t('levels.level.add'),"centered":"","size":"sm"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.modalSubmit}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" (uz)")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " (uz)"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " (uz)"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" (ru)")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " (ru)"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " (ru)"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title'))+" (en)")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " (en)"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " (en)"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "en", $$v)},expression:"dataForModal.name.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.score'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.score'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.score'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.score),callback:function ($$v) {_vm.$set(_vm.dataForModal, "score", $$v)},expression:"dataForModal.score"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }