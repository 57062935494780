<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow, BTable, VBTooltip } from 'bootstrap-vue';
import api from '@/services/api';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import TableActionBtns from '@/components/TableActionBtns';
import PageTitle from '@/components/PageTitle';
import { sortItems } from '@/util/helper';

export default {
    name: 'Levels',
    components: {
        PageTitle,
        TableActionBtns,
        BRow,
        BCol,
        BButton,
        BTable,
        BFormGroup,
        BFormInput,
        VBTooltip
    },
    mixins: [CRUDPageMixin],
    directives: {
        'b-tooltip': VBTooltip
    },
    data: () => ({
        levels: [],
        dataForModal: {
            name: {
                uz: '',
                ru: '',
                en: ''
            },
            score: null
        }
    }),
    computed: {
        tableHeader() {
            return [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'score',
                    label: this.$t('titles.score')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },
    methods: {
        async getLevels() {
            const { data } = await api.levels.get();
            this.levels = sortItems(data, 'id');
        },

        async updateLevel() {
            const { data } = await api.levels.put(this.updatingItem.id, this.dataForModal);
            this.updateDataToNewData(data);
            this.$toast.success(this.$t('success.updated.title'));
        },

        async createLevel() {
            const { data } = await api.levels.post(this.dataForModal);
            this.levels.push(data);
            this.$toast.success(this.$t('success.added'));
        },

        async deleteLevel(id, index) {
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await api.levels
                        .delete(id)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            this.levels.splice(index, 1);
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'Something went wrong!',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                        });
                }
            });
        },

        modalSubmit() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    try {
                        if (!this.updatingItem) {
                            await this.createLevel();
                        } else {
                            await this.updateLevel();
                        }
                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        }
    },
    mounted() {
        this.getLevels();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.levels') }}
            </template>
            <template v-if="hasAccess('levels', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <b-table
                :fields="tableHeader"
                :items="levels"
                :responsive="true"
                borderless
                outlined
                striped
            >
                <template #cell(index)="{ index }">
                    {{ index + 1 }}
                </template>
                <template #cell(name)="{ item }">
                    {{ item.name[localLang] }}
                </template>
                <template #cell(actions)="{ item, index }">
                    <div class="d-flex justify-content-end align-items-center">
                        <b-button
                            v-b-tooltip.hover.top="`Level gift`"
                            :to="{
                                name: 'Levels Gifts',
                                query: {
                                    id: item.id
                                }
                            }"
                            class="btn-icon"
                            variant="flat-dark"
                        >
                            <feather-icon icon="GiftIcon" />
                        </b-button>

                        <b-button
                            v-if="hasAccess('levels', 'update')"
                            v-b-tooltip.hover.top="`${$t('button.edit')}`"
                            class="btn-icon"
                            variant="flat-dark"
                            @click="openModalToUpdate(item)"
                        >
                            <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button
                            v-if="hasAccess('levels', 'delete')"
                            v-b-tooltip.hover.top="`${$t('button.delete')}`"
                            class="btn-icon"
                            variant="flat-danger"
                            @click="deleteLevel(item.id, index)"
                        >
                            <feather-icon icon="Trash2Icon" />
                        </b-button>
                    </div>
                </template>
            </b-table>
        </div>

        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="$t('levels.level.add')"
            centered
            size="sm"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME[uz]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} (uz)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (uz)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} (uz)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[ru]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} (ru)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (ru)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} (ru)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[en]    -->
                    <b-col cols="12">
                        <label>{{ $t('titles.title') }} (en)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} (en)`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} (en)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    SCORE    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.score') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.score')"
                            rules="required|numeric"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.score"
                                    :placeholder="$t('enter.score')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-button variant="success" @click="modalSubmit">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
